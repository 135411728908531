<template>
    <b-container>
        <br><br>
        <h4>Test notifiche utente</h4>
        <br><br>
        <b-row>
            <b-col sm="4">
                <p>Codice chiamata</p>
                <b-form-select v-model="httpCode" :options="options"></b-form-select>
            </b-col>
            <b-col sm="2">
                <p style="color:transparent;">Invio</p>
                <b-button class="btn-block" @click="sendRequest">Invio</b-button>
            </b-col>
            <b-col sm="6">
                <p style="color:transparent;">Risultato</p>
                <p>
                    {{message}}
                </p>
            </b-col>
        </b-row>
        <br><br>
    </b-container>
</template>

<script>
import Api from '../../api'
export default {
    name: 'notifications',
    data() {
        return {
            message: 'Invia una richiesta',
            httpCode: 200,
            options: [
                { value: 200, text: '200' },
                { value: 204, text: '204' },
                { value: 307, text: '307' },
                { value: 401, text: '401' },
                { value: 409, text: '409' },
                { value: 500, text: '500' },
                { value: 501, text: '501' },
            ]
        }
    },
    methods:
    {
        sendRequest: function()
        {
            let _this = this
            let parameters = {
                httpCode: _this.httpCode
            }
            Api
                .getNotification(parameters)
                .then((message) => { 
                    _this.message = message
                })
        }
    }
}
</script>