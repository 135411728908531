var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c("br"),
      _c("br"),
      _c("h4", [_vm._v("Test notifiche utente")]),
      _c("br"),
      _c("br"),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "4" } },
            [
              _c("p", [_vm._v("Codice chiamata")]),
              _c("b-form-select", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.httpCode,
                  callback: function ($$v) {
                    _vm.httpCode = $$v
                  },
                  expression: "httpCode",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "2" } },
            [
              _c("p", { staticStyle: { color: "transparent" } }, [
                _vm._v("Invio"),
              ]),
              _c(
                "b-button",
                { staticClass: "btn-block", on: { click: _vm.sendRequest } },
                [_vm._v("Invio")]
              ),
            ],
            1
          ),
          _c("b-col", { attrs: { sm: "6" } }, [
            _c("p", { staticStyle: { color: "transparent" } }, [
              _vm._v("Risultato"),
            ]),
            _c("p", [
              _vm._v(
                "\n                " + _vm._s(_vm.message) + "\n            "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("br"),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }